import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
} from "reactstrap";

const LoanCalculator = () => {
    const [loanDetails, setLoanDetails] = React.useState({
        roiType: "Flat Interest",
        emiCollection: "Daily",
        roi: "",
        loanAmount: "",
        term: "",
        emi: "",
    });

    const handleInputChange = (field, value) => {
        setLoanDetails({
            ...loanDetails,
            [field]: value,
        });
    };

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h3">Loan Calculator</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <Card>
                                        <CardBody>
                                            <CardTitle tag="h4">Loan Details</CardTitle>
                                            <FormGroup>
                                                <Label>ROI Type</Label>
                                                <Input
                                                    type="select"
                                                    value={loanDetails.roiType}
                                                    onChange={(e) => handleInputChange("roiType", e.target.value)}
                                                >
                                                    <option>Flat Interest</option>
                                                    <option>Reducing Balance</option>
                                                </Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>EMI Collection</Label>
                                                <Input
                                                    type="select"
                                                    value={loanDetails.emiCollection}
                                                    onChange={(e) => handleInputChange("emiCollection", e.target.value)}
                                                >
                                                    <option>Daily</option>
                                                    <option>Weekly</option>
                                                    <option>Monthly</option>
                                                </Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>ROI (% p.a.)</Label>
                                                <Input
                                                    type="number"
                                                    value={loanDetails.roi}
                                                    onChange={(e) => handleInputChange("roi", e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Loan Amount</Label>
                                                <Input
                                                    type="number"
                                                    value={loanDetails.loanAmount}
                                                    onChange={(e) => handleInputChange("loanAmount", e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Term</Label>
                                                <Input
                                                    type="number"
                                                    value={loanDetails.term}
                                                    onChange={(e) => handleInputChange("term", e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>EMI</Label>
                                                <Input
                                                    type="number"
                                                    value={loanDetails.emi}
                                                    onChange={(e) => handleInputChange("emi", e.target.value)}
                                                />
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <Card>
                                        <CardBody>
                                            <CardTitle tag="h4">Amortization Schedule</CardTitle>
                                            {/* Add content or table here for Amortization Schedule */}
                                            <p>Amortization details will appear here.</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default LoanCalculator;
