/*!

=========================================================
* Black Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col, Spinner,
} from "reactstrap";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import CstNotification from "../components/CstNotification";

const Login = () => {
  const [state, setState] = React.useState({});
  const [progressbar, setProgressbar] = React.useState(false);
  const [alert, setAlert] = React.useState({
    color: 'success',
    message: 'test message',
    autoDismiss: 7,
    place: 'tc',
    display: false,
  });
  const [userInput, setUserInput] = React.useState({
    email: '',
    password: ''
  });

  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const auth = getAuth();
  const navigate = useNavigate();

  function signInWithFirebase(){
    const email = userInput.email;
    const password = userInput.password;
    setAlert({
      color: 'success',
      message: '',
      autoDismiss: 7,
      place: 'tc',
      display: false,
    });

    if (email && password){
      setProgressbar(true);
      signInWithEmailAndPassword(auth, email, password)
        .then(async function ({ user }){
          const idToken = await user.getIdToken();
          console.log(idToken);
          axios.post('/sessionLogin', {idToken: idToken})
            .then(function () {
              signOut(auth);
              navigate("/admin/dashboard");
            }).catch(function (e) {
              setAlert({
                color: 'danger',
                message: e.toLocaleString(),
                autoDismiss: 7,
                place: 'tc',
                display: true,
              });
            setProgressbar(false);
          });
        }).catch(function(){
        setAlert({
          color: 'danger',
          message: 'Invalid username or password. Please try again.',
          autoDismiss: 7,
          place: 'tc',
          display: true,
        });
        setProgressbar(false);
      });
    }else {
      setAlert({
        color: 'warning',
        message: 'Please fill username and password',
        autoDismiss: 7,
        place: 'tc',
        display: true,
      });
    }
  }

  return (
    <>
      <div className="rna-container">
        {alert.display && <CstNotification color={alert.color} message={alert.message} autoDismiss={alert.autoDismiss} place={alert.place}/>}
      </div>
      <div className="content">
        <img alt="..." src={require("assets/img/login-bg.jpg")}/>
        <Container style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>
                  <img alt="..." src={require("assets/img/card-primary.png")}/>
                  <CardTitle tag="h1" className={'ml-1'}>Log in</CardTitle>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="text"
                      onFocus={(e) => setState({...state, emailFocus: true})}
                      onBlur={(e) => setState({...state, emailFocus: false})}
                      value={userInput.email}
                      onChange={(e) => setUserInput({...userInput, email: e.target.value})}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.passFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      onFocus={(e) => setState({...state, passFocus: true})}
                      onBlur={(e) => setState({...state, passFocus: false})}
                      value={userInput.password}
                      onChange={(e) => setUserInput({...userInput, password: e.target.value})}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <center>
                    <Spinner color="primary" hidden={!progressbar}/>
                  </center>
                  <Button
                    block
                    className="mb-3"
                    color="primary"
                    // onClick={(e) => e.preventDefault()}
                    onClick={signInWithFirebase}
                    size="lg"
                  >
                    Sign In
                  </Button>
                  <div className="pull-left">
                    <h6>
                      <a
                        className="link footer-link"
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        Create Account
                      </a>
                    </h6>
                  </div>
                  <div className="pull-right">
                    <h6>
                      <a
                        className="link footer-link"
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        Need Help?
                      </a>
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Login;
