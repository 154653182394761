/*!

=========================================================
* Black Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col, Spinner,
} from "reactstrap";
import ProfileImageUpload from "../../components/ProfileImageUpload";
import axios from "axios";
import CstNotification from "../../components/CstNotification";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {useSelector} from "react-redux";
import Select from "react-select";

const LoanOpeningForm = () => {
    // Initialize the default state for the form inputs
    const initInput = {
        // Loan Details
        loanDate: new Date().toISOString().slice(0, 10),
        memberId: "",
        memberName: "",
        uuid: '#',
        amount: 0,
        emiAmount: 0,
        principleEMI: 0,
        interestEMI: 0,
        loanTerm: 0,
        emiCount: 0,
        // Loan Plan Details
        planDetails: {
            id: '',
            name: '',
            type: '',
            emiMode: '',
            minAge: '',
            maxAge: '',
            minAmount: '',
            maxAmount: '',
            minTerm: '',
            maxTerm: '',
            interestRate: '',
            interestType: '',
            security: '',
            processingFee: 0,
            legalFee: 0,
            insuranceFeeRate: 0,
            gstRate: 0,
            valuerFeeRate: 0,
            gracePeriod: 0,
            penaltyType: '',
            penaltyRate: '',
        },
        // Guarantor Details
        guarantor: {
            memberCode: "",
            guarantorName: "",
            address: "",
            pinCode: "",
            phone: "",
            securityType: "",
        },
        // Co-Applicant Details
        coApplicant: {
            memberCode: "",
            coApplicantname: "",
            address: "",
            pinCode: "",
            phone: "",
            securityDetails: "",
        },
        // Deduction Details
        deductionDetails: {
            processingFee: "",
            legalAmount: "",
            gst: "",
            insuranceAmount: "",
        },
    };
    const initMemberInfo = {
        name: '',
        guardian: '',
        gender: '',
        dob: '',
        materialStatus: '',
        email: '',
        phone: '',
        address: '',
        aadhar: '',
        voter: '',
        pan: '',
        occupation: '',
        income: '',
        education: '',
    };

    const [formData, setFormData] = React.useState(initInput);
    const [memberData, setMemberData] = React.useState(initMemberInfo);

    // Initialize the state for error tracking
    const [cstError, setCstError] = React.useState({
        planId: '',
        loanDate: '',
        memberId: "",
        amount: '',
        emiAmount: '',
        principleEMI: '',
        interestEMI: '',
        loanTerm: '',
        emiCount: '',
    });
    const [progressbar, setProgressbar] = React.useState(false);
    const [alert, setAlert] = React.useState({
        color: 'success',
        message: 'test message',
        autoDismiss: 7,
        place: 'tc',
        display: false,
        sweetAlert: false,
    });
    const [fetched, setFetched] = React.useState(false);
    const [planList, setPlanList] = React.useState([]);

    const authStatus = useSelector((state) => state.auth.authState);

    if (!fetched){
        setFetched(true);
        axios.get('/api/loan/get-plans')
            .then(function (value){
                if (value.data.success){
                    if (value.data.plans.length > 0){
                        setPlanList(value.data.plans);
                    }else {
                        setAlert({
                            color: 'warning',
                            message: 'No plan found! Please add a plan.',
                            autoDismiss: 7,
                            place: 'tc',
                            display: true,
                            sweetAlert: false,
                        });
                    }
                }else {
                    setAlert({
                        color: 'danger',
                        message: value.data.error,
                        autoDismiss: 7,
                        place: 'tc',
                        display: true,
                        sweetAlert: false,
                    });
                }
            }).catch(function (error){
            setAlert({
                color: 'danger',
                message: error.toLocaleString(),
                autoDismiss: 7,
                place: 'tc',
                display: true,
                sweetAlert: false,
            });
        });
    }

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.planDetails.id) {
            formErrors.planId = "Loan Date is required.";
            isValid = false;
        }
        if (!formData.amount){
            formErrors.amount = "Please enter disbursement amount";
            isValid = false;
        }
        if (!formData.loanTerm){
            formErrors.loanTerm = "Please enter loan term";
            isValid = false;
        }
        if (!formData.memberId) {
            formErrors.memberId = "Please enter member id";
            isValid = false;
        }
        if (!formData.loanDate){
            formErrors.loanDate = "Loan Date is required.";
            isValid = false;
        }
        if (!formData.emiCount || !formData.principleEMI || !formData.interestEMI){
            setAlert({
                color: 'danger',
                message: 'Please select plan details then Loan Amount',
                autoDismiss: 7,
                place: 'tc',
                display: true,
                sweetAlert: false,
            });
            isValid = false;
        }

        setCstError(formErrors);
        return isValid;
    };
    // Handle input change for simple fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle input change for nested fields
    const handleNestedInputChange = (section, field, value) => {
        setFormData({
            ...formData,
            [section]: {
                ...formData[section],
                [field]: value,
            },
        });
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                setProgressbar(true);
                const submitData = await axios.post("/api/loan/loan-opening", formData);
                if (submitData.data.success) {
                    setFormData(initInput);
                    setAlert({
                        color: 'success',
                        message: submitData.data.success,
                        autoDismiss: 7,
                        place: 'tc',
                        display: true,
                        sweetAlert: true,
                    });
                } else {
                    setAlert({
                        color: 'warning',
                        message: submitData.data.error,
                        autoDismiss: 7,
                        place: 'tc',
                        display: true,
                        sweetAlert: false,
                    });
                }
                setProgressbar(false);
            } catch (e) {
                setProgressbar(false);
                setAlert({
                    color: 'danger',
                    message: e.toLocaleString(),
                    autoDismiss: 7,
                    place: 'tc',
                    display: true,
                    sweetAlert: false,
                });
            }
        }else {
            setAlert({
                color: 'danger',
                message: 'Please fill mandatory details and try again',
                autoDismiss: 7,
                place: 'tc',
                display: true,
                sweetAlert: false,
            });
        }
    };

    const handlePlanSelect = (plan) => {
        setFormData({
            ...formData,
            planDetails: plan,
        })
    }

    const handleLoanAmount = (amount) => {
        if (formData.planDetails.id){
            if (parseFloat(amount) <= formData.planDetails.maxAmount){
                setFormData({
                    ...formData,
                    amount: parseFloat(amount),
                    deductionDetails: {
                        processingFee: (parseFloat(amount) * formData.planDetails.processingFee / 100).toFixed(2),
                        legalAmount: parseFloat(formData.planDetails.legalFee).toFixed(2),
                        insuranceAmount: (parseFloat(amount) * formData.planDetails.insuranceFeeRate / 100).toFixed(2),
                        gst: (parseFloat(amount) * formData.planDetails.gstRate / 100).toFixed(2),
                    }
                });
            }else {
                setAlert({
                    color: 'warning',
                    message: 'Amount should be less than max amount and greater than min amount.',
                    autoDismiss: 7,
                    place: 'tc',
                    display: true,
                    sweetAlert: false,
                });
            }
        }else {
            setAlert({
                color: 'warning',
                message: 'Please select a loan plan first.',
                autoDismiss: 7,
                place: 'tc',
                display: true,
                sweetAlert: false,
            });
        }
    }

    const handleLoanTerm = (term) => {
        if (formData.planDetails.id){
            if (parseFloat(term) <= formData.planDetails.maxTerm){
                const totalPayment = parseFloat(formData.amount) + (parseFloat(formData.amount) * parseInt(term) * parseFloat(formData.planDetails.interestRate)) / (100 * 12);
                let numberOfEMI = 1;
                if (formData.planDetails.emiMode === 'daily'){
                    numberOfEMI = parseInt(term) * 30;
                }else if (formData.planDetails.emiMode === 'weekly'){
                    numberOfEMI = parseInt(term) * 4;
                }else if (formData.planDetails.emiMode === 'fortnightly') {
                    numberOfEMI = parseInt(term) * 2;
                }else if (formData.planDetails.emiMode === 'monthly') {
                    numberOfEMI = parseInt(term);
                }else if (formData.planDetails.emiMode === 'quarterly') {
                    numberOfEMI = Math.round(parseInt(term) / 3);
                }
                const emiAmount = Math.ceil(totalPayment/numberOfEMI);
                const principleEMI = Math.round(parseFloat(formData.amount) / numberOfEMI);

                setFormData({
                    ...formData,
                    loanTerm: parseInt(term),
                    emiAmount: emiAmount,
                    emiCount: numberOfEMI,
                    principleEMI: principleEMI,
                    interestEMI: emiAmount - principleEMI,
                });
            }else {
                setAlert({
                    color: 'warning',
                    message: 'Term should be less than max term and greater than min term.',
                    autoDismiss: 7,
                    place: 'tc',
                    display: true,
                    sweetAlert: false,
                });
            }
        }else {
            setAlert({
                color: 'warning',
                message: 'Please select a loan plan first.',
                autoDismiss: 7,
                place: 'tc',
                display: true,
                sweetAlert: false,
            });
        }
    }

    async function getMemberData(event) {
        setFormData({...formData, memberId: event.target.value});
        setAlert({...alert, color: 'info', message: '', display: false,});
        if (event.target.value.length === 11) {
            try {
                const fetchData = await axios.get(`/api/member/get-member-by-id/${event.target.value}`);
                if (fetchData.data.success) {
                    setMemberData(fetchData.data);
                    setFormData({
                        ...formData,
                        memberId: fetchData.data.id,
                        memberName: fetchData.data.name,
                        uuid: fetchData.data.uuid,
                    });
                }else {
                    setMemberData(initMemberInfo);
                    setAlert({
                        color: 'warning',
                        message: fetchData.data.error,
                        autoDismiss: 7,
                        place: 'tc',
                        display: true,
                        sweetAlert: false,
                    });
                }
            } catch (e) {
                setMemberData(initMemberInfo);
                setAlert({
                    color: 'danger',
                    message: e.toLocaleString(),
                    autoDismiss: 7,
                    place: 'tc',
                    display: true,
                    sweetAlert: false,
                })
            }
        }
    }

    return (
        <>
            <div className="rna-container">
                {alert.display && <CstNotification color={alert.color} message={alert.message} autoDismiss={alert.autoDismiss} place={alert.place}/>}
                {alert.sweetAlert && <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Good job!"
                    onConfirm={() => setAlert({...alert, sweetAlert: false})}
                    onCancel={() => setAlert({...alert, sweetAlert: false})}
                    confirmBtnBsStyle="success"
                    btnSize=""
                >
                    {alert.message}
                </ReactBSAlert>}
            </div>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">Loan Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <Row className="d-flex flex-wrap">
                                            <Col md="3">
                                                <Label>Loan Date*</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="date"
                                                        name="loanDate"
                                                        value={formData.loanDate}
                                                        onChange={handleInputChange}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.loanDate}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <Label>Loan Plan Name*</Label>
                                                <Select
                                                    className="react-select info"
                                                    classNamePrefix="react-select"
                                                    name="planSelect"
                                                    onChange={(value) => handlePlanSelect(value)}
                                                    options={planList}
                                                    placeholder="Select an Option"
                                                />
                                                <p style={{color: 'red'}}>{cstError.planId}</p>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Loan Name *</Label>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        value={formData.planDetails.name}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Loan Type *</Label>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        value={formData.planDetails.type}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Loan EMI Mode *</Label>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        value={formData.planDetails.emiMode}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Min Age *</Label>
                                                    <Input
                                                        type="number"
                                                        name="minAge"
                                                        value={formData.planDetails.minAge}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Max Age *</Label>
                                                    <Input
                                                        type="number"
                                                        name="maxAge"
                                                        value={formData.planDetails.maxAge}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Min Amount *</Label>
                                                    <Input
                                                        type="number"
                                                        name="minAmount"
                                                        value={formData.planDetails.minAmount}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Max Amount *</Label>
                                                    <Input
                                                        type="number"
                                                        name="maxAmount"
                                                        value={formData.planDetails.maxAmount}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Min Term (month) *</Label>
                                                    <Input
                                                        type="number"
                                                        name="minTerm"
                                                        value={formData.planDetails.minTerm}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Max Term (month) *</Label>
                                                    <Input
                                                        type="number"
                                                        name="maxTerm"
                                                        value={formData.planDetails.maxTerm}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Interest Rate *</Label>
                                                    <Input
                                                        type="number"
                                                        name="interestRate"
                                                        value={formData.planDetails.interestRate}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Security Type</Label>
                                                    <Input
                                                        type='text'
                                                        name="security"
                                                        value={formData.planDetails.security}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Loan Amount *</Label>
                                                    <Input
                                                        type='text'
                                                        name="security"
                                                        value={formData.amount}
                                                        onChange={(event) => handleLoanAmount(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Loan Term (month) *</Label>
                                                    <Input
                                                        type='text'
                                                        name="security"
                                                        value={formData.loanTerm}
                                                        onChange={(event) => handleLoanTerm(event.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.loanTerm}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>EMI Amount *</Label>
                                                    <Input
                                                        type='text'
                                                        name="security"
                                                        value={formData.emiAmount}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Number of Installment</Label>
                                                    <Input
                                                        type='text'
                                                        name="security"
                                                        value={formData.emiCount}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">Member Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <Row className="d-flex flex-wrap">
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>Member ID</Label>
                                                    <Input
                                                        type="text"
                                                        name="memberId"
                                                        value={formData.memberId}
                                                        onChange={getMemberData}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.memberId}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md="3">
                                                <Label>Member Name</Label>
                                                <FormGroup>
                                                    <Input type={'text'} value={memberData.name} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md="3">
                                                <Label>Father/Mother/Spouse</Label>
                                                <FormGroup>
                                                    <Input type={'text'} value={memberData.guardian} readOnly={true} />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md="3">
                                                <Label>Gender</Label>
                                                <Input type={'text'} value={memberData.gender} readOnly={true}/>
                                            </Col>
                                            <Col className="pr-1" md="3">
                                                <Label>Registration Date</Label>
                                                <FormGroup>
                                                    <Input type={'date'} value={memberData.date} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md="3">
                                                <Label>Date of Birth</Label>
                                                <FormGroup>
                                                    <Input type={'date'} value={memberData.dob} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md={'3'}>
                                                <Label>Material Status</Label>
                                                <Input type="text" name="select" id="materialSelect" value={memberData.materialStatus} readOnly={true}/>
                                            </Col>
                                            <Col className="pr-1" md={'3'}>
                                                <Label>Phone Number</Label>
                                                <FormGroup>
                                                    <Input type={'text'} value={memberData.phone} readOnly={true} />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md={'3'}>
                                                <Label>Email</Label>
                                                <FormGroup>
                                                    <Input type={'email'} value={memberData.email} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md={'3'}>
                                                <Label>Aadhar Number</Label>
                                                <FormGroup>
                                                    <Input type={'text'} value={memberData.aadhar} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md={'3'}>
                                                <Label>Voter Number</Label>
                                                <FormGroup>
                                                    <Input type={'text'} value={memberData.voter} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md={'3'}>
                                                <Label>PAN Number</Label>
                                                <FormGroup>
                                                    <Input type={'text'} value={memberData.pan} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md={'3'}>
                                                <Label>Monthly Income</Label>
                                                <FormGroup>
                                                    <Input type={'number'} value={memberData.income} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md={'3'}>
                                                <Label>Occupation</Label>
                                                <FormGroup>
                                                    <Input type={'text'} value={memberData.occupation} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md={'3'}>
                                                <Label>Educational Qualification</Label>
                                                <FormGroup>
                                                    <Input type={'text'} value={memberData.education} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md={'12'}>
                                                <Label>Full Address with Pin Code</Label>
                                                <FormGroup>
                                                    <Input type={'textarea'} value={memberData.address} aria-colspan={3} readOnly={true}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">Photo Upload </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="4" className={'text-center'}>
                                        <ProfileImageUpload
                                            id={'profile'}
                                            uuid={formData.uuid}
                                            bankId={authStatus.bankId}
                                            changeBtnClasses="btn-simple"
                                            addBtnClasses="btn-simple"
                                            removeBtnClasses="btn-simple"
                                        />
                                        <p className="mt-2">Upload the profile photo here.</p>
                                    </Col>
                                    <Col md="4" className={'text-center'}>
                                        <ProfileImageUpload
                                            id={'profile-joint'}
                                            uuid={formData.uuid}
                                            bankId={authStatus.bankId}
                                            changeBtnClasses="btn-simple"
                                            addBtnClasses="btn-simple"
                                            removeBtnClasses="btn-simple"
                                        />
                                        <p className="mt-2">Upload the joint photo
                                            here.</p> {/* Text under the second upload field */}
                                    </Col>
                                    <Col md="4" className={'text-center'}>
                                        <ProfileImageUpload
                                            id={'signature'}
                                            uuid={formData.uuid}
                                            bankId={authStatus.bankId}
                                            changeBtnClasses="btn-simple"
                                            addBtnClasses="btn-simple"
                                            removeBtnClasses="btn-simple"
                                        />
                                        <p className="mt-2">Upload the Signature
                                            here.</p> {/* Text under the third upload field */}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/*Guarantor Details*/}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">Guarantor Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="3">
                                            <Label>Member Code</Label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="memberCode"
                                                    value={formData.guarantor.memberCode}
                                                    onChange={(e) => handleNestedInputChange('guarantor', 'memberCode', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.guarantor?.memberCode}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Guarantor Name</Label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="guarantorName"
                                                    value={formData.guarantor.guarantorName}
                                                    onChange={(e) => handleNestedInputChange('guarantor', 'guarantorName', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.guarantor?.guarantorName}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Address</Label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="address"
                                                    value={formData.guarantor.address}
                                                    onChange={(e) => handleNestedInputChange('guarantor', 'address', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.guarantor?.address}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Pin Code</Label>
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    name="pinCode"
                                                    value={formData.guarantor.pinCode}
                                                    onChange={(e) => handleNestedInputChange('guarantor', 'pinCode', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.guarantor?.pinCode}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Phone</Label>
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    name="phone"
                                                    value={formData.guarantor.phone}
                                                    onChange={(e) => handleNestedInputChange('guarantor', 'phone', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.guarantor?.phone}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Security Type</Label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="securityType"
                                                    value={formData.guarantor.securityType}
                                                    onChange={(e) => handleNestedInputChange('guarantor', 'securityType', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.guarantor?.securityType}</p>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/*Co-Applicant Details*/}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">Co-Applicant Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="3">
                                            <Label>Member Code</Label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="memberCode"
                                                    value={formData.coApplicant.memberCode}
                                                    onChange={(e) => handleNestedInputChange('coApplicant', 'memberCode', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.coApplicant?.memberCode}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Co-Applicant Name</Label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="coApplicantname"
                                                    value={formData.coApplicant.coApplicantname}
                                                    onChange={(e) => handleNestedInputChange('coApplicant', 'coApplicantname', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.coApplicant?.coApplicantname}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Address</Label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="address"
                                                    value={formData.coApplicant.address}
                                                    onChange={(e) => handleNestedInputChange('coApplicant', 'address', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.coApplicant?.address}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Pin Code</Label>
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    name="pinCode"
                                                    value={formData.coApplicant.pinCode}
                                                    onChange={(e) => handleNestedInputChange('coApplicant', 'pinCode', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.coApplicant?.pinCode}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Phone</Label>
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    name="phone"
                                                    value={formData.coApplicant.phone}
                                                    onChange={(e) => handleNestedInputChange('coApplicant', 'phone', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.coApplicant?.phone}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Security Details</Label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="securityDetails"
                                                    value={formData.coApplicant.securityDetails}
                                                    onChange={(e) => handleNestedInputChange('coApplicant', 'securityDetails', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.coApplicant?.securityDetails}</p>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/*Deduction Details*/}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">Deduction Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="3">
                                            <Label>Processing Fee</Label>
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    name="processingFee"
                                                    value={formData.deductionDetails.processingFee}
                                                    onChange={(e) => handleNestedInputChange('deductionDetails', 'processingFee', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.deductionDetails?.processingFee}</p>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <Label>Legal Amount</Label>
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    name="legalAmount"
                                                    value={formData.deductionDetails.legalAmount}
                                                    onChange={(e) => handleNestedInputChange('deductionDetails', 'legalAmount', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.deductionDetails?.legalAmount}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Label>GST</Label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="gst"
                                                    value={formData.deductionDetails.gst}
                                                    onChange={(e) => handleNestedInputChange('deductionDetails', 'gst', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.deductionDetails?.gst}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Label>Insurance Amount</Label>
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    name="insuranceAmount"
                                                    value={formData.deductionDetails.insuranceAmount}
                                                    onChange={(e) => handleNestedInputChange('deductionDetails', 'insuranceAmount', e.target.value)}
                                                />
                                                <p style={{color: 'red'}}>{cstError.deductionDetails?.insuranceAmount}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <Col md="12" className={'text-center'}>
                                <CardFooter>
                                    <center>
                                        <Spinner color="info" hidden={!progressbar}/>
                                    </center>
                                    <Button className="btn-fill" color="info" type="button" onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </CardFooter>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default LoanOpeningForm;


