/*!

=========================================================
* Black Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import VectorMap from "views/maps/VectorMap.js";
// import GoogleMaps from "views/maps/GoogleMaps.js";
// import FullScreenMap from "views/maps/FullScreenMap.js";
// import ReactTables from "views/tables/ReactTables.js";
// import RegularTables from "views/tables/RegularTables.js";
// import ExtendedTables from "views/tables/ExtendedTables.js";
// import Wizard from "views/forms/Wizard.js";
// import ValidationForms from "views/forms/ValidationForms.js";
// import ExtendedForms from "views/forms/ExtendedForms.js";
// import RegularForms from "views/forms/RegularForms.js";
// import Calendar from "views/Calendar.js";
// import Widgets from "views/Widgets.js";
// import Charts from "views/Charts.js";
// import Buttons from "views/components/Buttons.js";
// import SweetAlert from "views/components/SweetAlert.js";
// import Notifications from "views/components/Notifications.js";
// import Grid from "views/components/Grid.js";
// import Typography from "views/components/Typography.js";
// import Panels from "views/components/Panels.js";
// import Icons from "views/components/Icons.js";
// import Pricing from "views/pages/Pricing.js";
// import Register from "views/pages/Register.js";
// import Timeline from "views/pages/Timeline.js";
// import User from "views/pages/User.js";
// import Login from "views/pages/Login.js";
// import Rtl from "views/pages/Rtl.js";
// import Lock from "views/pages/Lock.js";

import Dashboard from "views/Dashboard.js";
// import Buttons from "views/components/Buttons.js";
// import SweetAlert from "views/components/SweetAlert.js";
// import Notifications from "views/components/Notifications.js";
// import Grid from "views/components/Grid.js";
// import Typography from "views/components/Typography.js";
// import Panels from "views/components/Panels.js";
// import Icons from "views/components/Icons.js";
// import Pricing from "views/pages/Pricing.js";
// import Register from "views/pages/Register.js";
// import Timeline from "views/pages/Timeline.js";
// import User from "views/pages/User.js";
// import Login from "views/pages/Login.js";
// import Rtl from "views/pages/Rtl.js";
// import Lock from "views/pages/Lock.js";

// Custom Pages
import AddMember from "./views/custom/member/addMember/AddMember";
import ViewMembers from "./views/custom/member/viewMember/ViewMembers";
import SearchMembers from "./views/custom/member/searchMember/SearchMembers";
import EditMember from "./views/custom/member/editMember/EditMember";
import AddAdvisor from "./views/custom/advisor/addAdvisor/AddAdvisor";
import UpdateAdvisor from "./views/custom/advisor/updateAdvisor/UpdateAdvisor";
import AdvisorTree from "./views/custom/advisor/advisorTree/AdvisorTree";
import ViewAdvisor from "./views/custom/advisor/viewAdvisor/ViewAdvisor";

// Employee Section
import AddEmployee from "./views/custom/employee/AddEmployeeKYC/AddEmployeeKYC";
import AddDesignationMaster from "./views/custom/employee/AddDesignation/AddDesignationMaster";
import AddDepartment from "./views/custom/employee/AddDepartment/AddDepartment";
import SearchEmployee from "./views/custom/employee/SearchEmployee/SearchEmployee";

// Deposit
import DepositAccountOpening from "./views/custom/deposits/accountOpening/AccountOpening";
import DepositTransaction from "./views/custom/deposits/transaction/DepositTransaction";
import DepositStatement from "./views/custom/deposits/accountStatement/AccountStatement";
import DailyCollection from "./views/custom/deposits/dailyCollection/DailyCollection";

// Loan
import LoanPlanCreation from "./views/custom/loan/planCreation/PlanCreation";
import LoanOpening from "./views/custom/loan/LoanOpening/LoanOpening";
import LoanRepaymentForm from "./views/custom/loan/loanRepayment/LoanRepayment";
import LoanEmiPayment from "./views/custom/loan/loanEmiPayment/LoanEmiPayment";
import LoanPreSettlement from "./views/custom/loan/Loan Pre Settlement/LoanPreSettlement";
import LoanCalculator from "./views/custom/loan/Loan Calculator/LoanCalculator";
import LoanAccountStatement from "./views/custom/loan/Loan Account Statement/LoanAccountStatement";

// Authorization
import AuthorizeTransaction from "./views/custom/authorization/AuthorizeTransaction";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard/>,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Member",
    rtlName: "সদস্য",
    icon: "tim-icons icon-badge",
    state: "memberCollapse",
    views: [
      {
        path: "/add-member",
        name: "Add Member",
        rtlName: "সদস্য যোগ",
        mini: "ADD",
        rtlMini: "যোগ",
        component: <AddMember/>,
        layout: "/member",
      },
      {
        path: "/update-member",
        name: "Edit Member",
        rtlName: "সদস্য সংশোধন",
        mini: "EDIT",
        rtlMini: "সংশোধন",
        component: <EditMember/>,
        layout: "/member",
      },
      {
        path: "/view-members",
        name: "View Members",
        rtlName: "সদস্য তালিকা",
        mini: "VIEW",
        rtlMini: "তালিকা",
        component: <ViewMembers/>,
        layout: "/member",
      },
      {
        path: "/search-members",
        name: "Search Members",
        rtlName: "অনুসন্ধান করুন",
        mini: "FIND",
        rtlMini: "অনুসন্ধান",
        component: <SearchMembers/>,
        layout: "/member",
      },
    ],
  },
  {
    collapse: true,
    name: "Advisor/CP Agent",
    rtlName: "আমানত",
    icon: "tim-icons icon-user-run",
    state: "advisorCollapse",
    views: [
      {
        path: "/add-advisor",
        name: "Create Advisor",
        rtlName: "যোগ করুন",
        mini: "ADD",
        rtlMini: "যোগ",
        component: <AddAdvisor/>,
        layout: "/member",
      },
      {
        path: "/update-advisor",
        name: "Update Advisor",
        rtlName: "সংশোধন করুন",
        mini: "EDIT",
        rtlMini: "সংশোধন",
        component: <UpdateAdvisor/>,
        layout: "/member",
      },
      {
        path: "/view-advisor",
        name: "Advisor List",
        rtlName: "গাছ",
        mini: "LIST",
        rtlMini: "গাছ",
        component: <ViewAdvisor/>,
        layout: "/member",
      },
      {
        path: "/advisor-tree",
        name: "Advisor Tree",
        rtlName: "গাছ",
        mini: "EDIT",
        rtlMini: "গাছ",
        component: <AdvisorTree/>,
        layout: "/member",
      },
    ],
  },
  {
    collapse: true,
    name: "Employee",
    rtlName: "সদস্য",
    icon: "tim-icons icon-laptop",
    state: "employeeCollapse",
    views: [
      {
        path: "/add-designation",
        name: "Add Designation",
        rtlName: "সদস্য যোগ",
        mini: "ADD",
        rtlMini: "যোগ",
        component: <AddDesignationMaster />,
        layout: "/member",
      },
      {
        path: "/add-department",
        name: "Add Department",
        rtlName: "সদস্য যোগ",
        mini: "ADD",
        rtlMini: "যোগ",
        component: <AddDepartment />,
        layout: "/member",
      },
      {
        path: "/add-Employee",
        name: "Add Employee",
        rtlName: "সদস্য যোগ",
        mini: "ADD",
        rtlMini: "যোগ",
        component: <AddEmployee />,
        layout: "/member",
      },
      {
        path: "/employee-list",
        name: "Employee List",
        rtlName: "সদস্য যোগ",
        mini: "ADD",
        rtlMini: "যোগ",
        component: <SearchEmployee />,
        layout: "/member",
      },
    ],
  },
  {
    collapse: true,
    name: "Deposits",
    rtlName: "আমানত",
    icon: "tim-icons icon-bank",
    state: "depositCollapse",
    views: [
      {
        path: "/account-opening",
        name: "Deposit Opening",
        rtlName: "যোগ করুন",
        mini: "ADD",
        rtlMini: "যোগ",
        component: <DepositAccountOpening/>,
        layout: "/deposit",
      },
      {
        path: "/deposit-transactions",
        name: "Deposit Transactions",
        rtlName: "যোগ করুন",
        mini: "TANS",
        rtlMini: "যোগ",
        component: <DepositTransaction/>,
        layout: "/deposit",
      },
      {
        path: "/daily-collection-agent",
        name: "Daily Collection",
        rtlName: "যোগ করুন",
        mini: "DAILY",
        rtlMini: "যোগ",
        component: <DailyCollection/>,
        layout: "/deposit",
      },
      {
        path: "/deposit-statement",
        name: "Account Statement",
        rtlName: "যোগ করুন",
        mini: "STAT",
        rtlMini: "যোগ",
        component: <DepositStatement/>,
        layout: "/deposit",
      },
    ],
  },
  {
    collapse: true,
    name: "Loan",
    icon: "tim-icons icon-money-coins",
    state: "loanCollapse",
    views: [
      {
        path: "/loan-plan-creation",
        name: "Plan Creation",
        mini: "PLAN",
        component: <LoanPlanCreation/>,
        layout: "/loan",
      },
      {
        path: "/loan-application",
        name: "Loan Application",
        mini: "OPEN",
        component: <LoanOpening/>,
        layout: "/loan",
      },
      {
        path: "/Loan-RePayment",
        name: "Loan RePayment",
        rtlName: "সদস্য সংশোধন",
        mini: "PAY",
        rtlMini: "সংশোধন",
        component: <LoanRepaymentForm/>,
        layout: "/member",
      },
      {
        path: "/Regular-EMI-Repayment",
        name: "Regular EMI Repayment",
        rtlName: "সদস্য সংশোধন",
        mini: "EMI",
        rtlMini: "সংশোধন",
        component: <LoanEmiPayment/>,
        layout: "/member",
      },
      {
        path: "/Loan-Pre-Settlement",
        name: "Loan Pre Settlement",
        rtlName: "সদস্য সংশোধন",
        mini: "EDIT",
        rtlMini: "সংশোধন",
        component: <LoanPreSettlement/>,
        layout: "/member",
      },
      {
        path: "/Loan-Calculator",
        name: "Loan Calculator",
        rtlName: "সদস্য সংশোধন",
        mini: "EDIT",
        rtlMini: "সংশোধন",
        component: <LoanCalculator/>,
        layout: "/member",
      },

      {
        path: "/Loan-Statement",
        name: "Loan Statement",
        rtlName: "সদস্য সংশোধন",
        mini: "EDIT",
        rtlMini: "সংশোধন",
        component: <LoanAccountStatement/>,
        layout: "/member",
      },
    ],
  },
  {
    collapse: true,
    name: "Authorization",
    rtlName: "আমানত",
    icon: "tim-icons icon-components",
    state: "authorizeCollapse",
    views: [
      {
        path: "/transactions",
        name: "Transactions",
        rtlName: "অনুমোদন",
        mini: "TRAN",
        rtlMini: "অনু",
        component: <AuthorizeTransaction/>,
        layout: "/authorization",
      },
    ],
  },

  // //   OOB Pages
  // {
  //   collapse: true,
  //   name: "Pages",
  //   rtlName: "صفحات",
  //   icon: "tim-icons icon-image-02",
  //   state: "pagesCollapse",
  //   views: [
  //     {
  //       path: "/pricing",
  //       name: "Pricing",
  //       rtlName: "عالتسعير",
  //       mini: "P",
  //       rtlMini: "ع",
  //       component: <Pricing/>,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/rtl-support",
  //       name: "RTL Support",
  //       rtlName: "صودعم رتل",
  //       mini: "RS",
  //       rtlMini: "صو",
  //       component: <Rtl/>,
  //       layout: "/rtl",
  //     },
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       rtlName: "تيالجدول الزمني",
  //       mini: "T",
  //       rtlMini: "تي",
  //       component: <Timeline/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/login",
  //       name: "Login",
  //       rtlName: "هعذاتسجيل الدخول",
  //       mini: "L",
  //       rtlMini: "هعذا",
  //       component: <Login/>,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/register",
  //       name: "Register",
  //       rtlName: "تسجيل",
  //       mini: "R",
  //       rtlMini: "صع",
  //       component: <Register/>,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/lock-screen",
  //       name: "Lock Screen",
  //       rtlName: "اقفل الشاشة",
  //       mini: "LS",
  //       rtlMini: "هذاع",
  //       component: <Lock/>,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/user-profile",
  //       name: "User Profile",
  //       rtlName: "ملف تعريفي للمستخدم",
  //       mini: "UP",
  //       rtlMini: "شع",
  //       component: <User/>,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   rtlName: "المكونات",
  //   icon: "tim-icons icon-molecule-40",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       collapse: true,
  //       name: "Multi Level Collapse",
  //       rtlName: "انهيار متعدد المستويات",
  //       mini: "MLT",
  //       rtlMini: "ر",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "/buttons",
  //           name: "Buttons",
  //           rtlName: "وصفت",
  //           mini: "B",
  //           rtlMini: "ب",
  //           component: <Buttons/>,
  //           layout: "/admin",
  //         },
  //       ],
  //     },
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       rtlName: "وصفت",
  //       mini: "B",
  //       rtlMini: "ب",
  //       component: <Buttons/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       rtlName: "نظام الشبكة",
  //       mini: "GS",
  //       rtlMini: "زو",
  //       component: <Grid/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       rtlName: "لوحات",
  //       mini: "P",
  //       rtlMini: "ع",
  //       component: <Panels/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       rtlName: "الحلو تنبيه",
  //       mini: "SA",
  //       rtlMini: "ومن",
  //       component: <SweetAlert/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       rtlName: "إخطارات",
  //       mini: "N",
  //       rtlMini: "ن",
  //       component: <Notifications/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       rtlName: "الرموز",
  //       mini: "I",
  //       rtlMini: "و",
  //       component: <Icons/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       rtlName: "طباعة",
  //       mini: "T",
  //       rtlMini: "ر",
  //       component: <Typography/>,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   rtlName: "إستمارات",
  //   icon: "tim-icons icon-notes",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       name: "Regular Forms",
  //       rtlName: "أشكال عادية",
  //       mini: "RF",
  //       rtlMini: "صو",
  //       component: <RegularForms/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/extended-forms",
  //       name: "Extended Forms",
  //       rtlName: "نماذج موسعة",
  //       mini: "EF",
  //       rtlMini: "هوو",
  //       component: <ExtendedForms/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/validation-forms",
  //       name: "Validation Forms",
  //       rtlName: "نماذج التحقق من الصحة",
  //       mini: "VF",
  //       rtlMini: "تو",
  //       component: <ValidationForms/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/wizard",
  //       name: "Wizard",
  //       rtlName: "ساحر",
  //       mini: "W",
  //       rtlMini: "ث",
  //       component: <Wizard/>,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   rtlName: "الجداول",
  //   icon: "tim-icons icon-puzzle-10",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       rtlName: "طاولات عادية",
  //       mini: "RT",
  //       rtlMini: "صر",
  //       component: <RegularTables/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       rtlName: "جداول ممتدة",
  //       mini: "ET",
  //       rtlMini: "هور",
  //       component: <ExtendedTables/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "React Tables",
  //       rtlName: "رد فعل الطاولة",
  //       mini: "RT",
  //       rtlMini: "در",
  //       component: <ReactTables/>,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-pin",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       rtlName: "خرائط جوجل",
  //       mini: "GM",
  //       rtlMini: "زم",
  //       component: <GoogleMaps/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/full-screen-map",
  //       name: "Full Screen Map",
  //       rtlName: "خريطة كاملة الشاشة",
  //       mini: "FSM",
  //       rtlMini: "ووم",
  //       component: <FullScreenMap/>,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector-map",
  //       name: "Vector Map",
  //       rtlName: "خريطة المتجه",
  //       mini: "VM",
  //       rtlMini: "تم",
  //       component: <VectorMap/>,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   rtlName: "الحاجيات",
  //   icon: "tim-icons icon-settings",
  //   component: <Widgets/>,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   rtlName: "الرسوم البيانية",
  //   icon: "tim-icons icon-chart-bar-32",
  //   component: <Charts/>,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   rtlName: "التقويم",
  //   icon: "tim-icons icon-time-alarm",
  //   component: <Calendar/>,
  //   layout: "/admin",
  // },
];

export default routes;
