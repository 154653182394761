/*!

=========================================================
* Black Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col, Spinner,
} from "reactstrap";
import ProfileImageUpload from "../../components/ProfileImageUpload";
import axios from "axios";
import CstNotification from "../../components/CstNotification";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {useSelector} from "react-redux";
import Select from "react-select";

const EditMember = () => {
  const initInput = {
    id: '',
    date: '',
    name: '',
    guardian: '',
    gender: 'male',
    dob: '',
    materialStatus: 'unmarried',
    email: '',
    phone: '',
    address: '',
    aadhar: '',
    voter: '',
    pan: '',
    occupation: '',
    income: '',
    education: '',
    nominee: {
      name: '',
      relation: '',
      dob: '',
      aadhar: '',
      voter: '',
      pan: '',
    },
    uuid: '',
  };
  const [userInput, setUserInput] = React.useState(initInput);
  const [cstError, setCstError] = React.useState({
    name: '',
    guardian: '',
    phone: '',
    date: '',
  });
  const [progressbar, setProgressbar] = React.useState(false);
  const [alert, setAlert] = React.useState({
    color: 'success',
    message: 'test message',
    autoDismiss: 7,
    place: 'tc',
    display: false,
    sweetAlert: false,
  });
  const [members, setMembers] = React.useState([]);

  const authStatus = useSelector((state) => state.auth.authState);

  useEffect(() => {
    axios.get('/api/member/get-all-members').then((res) => {
      console.log(res.data);
      if (res.data.success) {
        setMembers(res.data.data);
      }else {
        setAlert({
          color: 'warning',
          message: res.data.error,
          autoDismiss: 7,
          place: 'tc',
          display: true,
          sweetAlert: false,
        });
      }
    });
  }, []);

  async function onSubmit(){
    const inputValid = validateInput(userInput);
    if(inputValid){
      try {
        setProgressbar(true);
        const submitData = await axios.post('/api/member/update-member', userInput);
        if(submitData.data.success){
          setUserInput(initInput);
          setAlert({
            color: 'success',
            message: submitData.data.success,
            autoDismiss: 7,
            place: 'tc',
            display: true,
            sweetAlert: true,
          });
        }else {
          setAlert({
            color: 'warning',
            message: submitData.data.error,
            autoDismiss: 7,
            place: 'tc',
            display: true,
            sweetAlert: false,
          });
        }
        setProgressbar(false);
      }catch (e) {
        setAlert({
          color: 'danger',
          message: e.toLocaleString(),
          autoDismiss: 7,
          place: 'tc',
          display: true,
          sweetAlert: false,
        });
      }
    }
  }

  function validateInput(inputValue) {
    let temp = {};
    let isValid = true;
    if (!inputValue.name){
      temp = {...temp, name: 'this is required'};
      isValid = false;
    }
    if (!inputValue.guardian){
      temp = {...temp, guardian: 'this is required'};
      isValid = false;
    }
    if (inputValue.phone.length !== 10){
      temp = {...temp, phone: 'this is required'};
      isValid = false;
    }
    if (!inputValue.date){
      temp = {...temp, date: 'this is required'};
      isValid = false;
    }
    setCstError(temp);
    return isValid;
  }

  return (
    <>
      <div className="rna-container">
        {alert.display && <CstNotification color={alert.color} message={alert.message} autoDismiss={alert.autoDismiss} place={alert.place}/>}
        {alert.sweetAlert && <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success!"
          onConfirm={() => setAlert({...alert, sweetAlert: false})}
          onCancel={() => setAlert({...alert, sweetAlert: false})}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          {alert.message}
        </ReactBSAlert>}
      </div>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody className={'mt-2'}>
                <Row>
                  <Col md={9}>
                    <Label>Select a Member Id</Label>
                    <FormGroup>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="memberSelect"
                        onChange={(value) => setUserInput({...userInput, id: value, ...value})}
                        options={members}
                        placeholder=''
                      />
                      <p style={{color: 'red'}}>{cstError.referrer}</p>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h3">Basic Details</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={9}>
                    <Form>
                      <Row>
                        <Col className="pr-1" md="3">
                          <Label>Member Name</Label>
                          <FormGroup>
                            <Input type={'text'} value={userInput.name}
                                   onChange={(event) => setUserInput({...userInput, name: event.target.value.toUpperCase()})}/>
                            <p style={{color: 'red'}}>{cstError.name}</p>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <Label>Father/Mother/Spouse</Label>
                          <FormGroup>
                            <Input type={'text'} value={userInput.guardian}
                                   onChange={(event) => setUserInput({...userInput, guardian: event.target.value.toUpperCase()})}/>
                            <p style={{color: 'red'}}>{cstError.guardian}</p>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <Label>Gender</Label>
                          <Input type="select" name="select" id="genderSelect"
                                 value={userInput.gender}
                                 onChange={(event) => setUserInput({...userInput, gender: event.target.value})}
                          >
                            <option value={'male'}>Male</option>
                            <option value={'female'}>Female</option>
                            <option value={'other'}>Other</option>
                          </Input>
                        </Col>
                        <Col className="pr-1" md="3">
                          <Label>Registration Date</Label>
                          <FormGroup>
                            <Input type={'date'} value={userInput.date}
                                   onChange={(event) => setUserInput({...userInput, date: event.target.value})}/>
                            <p style={{color: 'red'}}>{cstError.date}</p>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <Label>Date of Birth</Label>
                          <FormGroup>
                            <Input type={'date'} value={userInput.dob}
                                   onChange={(event) => setUserInput({...userInput, dob: event.target.value})}/>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md={'3'}>
                          <Label>Material Status</Label>
                          <Input type="select" name="select" id="materialSelect"
                                 value={userInput.materialStatus}
                                 onChange={(event) => setUserInput({...userInput, materialStatus: event.target.value})}
                          >
                            <option value={'unmarried'}>Unmarried</option>
                            <option value={'married'}>Married</option>
                            <option value={'divorced'}>Divorced</option>
                            <option value={'widow'}>Widow</option>
                          </Input>
                        </Col>
                        <Col className="pr-1" md={'3'}>
                          <Label>Phone Number</Label>
                          <FormGroup>
                            <Input type={'text'} value={userInput.phone}
                                   onChange={(event) => setUserInput({...userInput, phone: event.target.value})}/>
                            <p style={{color: 'red'}}>{cstError.phone}</p>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md={'3'}>
                          <Label>Email</Label>
                          <FormGroup>
                            <Input type={'email'} value={userInput.email}
                                   onChange={(event) => setUserInput({...userInput, email: event.target.value})}/>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md={'3'}>
                          <Label>Aadhar Number</Label>
                          <FormGroup>
                            <Input type={'text'} value={userInput.aadhar}
                                   onChange={(event) => setUserInput({...userInput, aadhar: event.target.value})}/>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md={'3'}>
                          <Label>Voter Number</Label>
                          <FormGroup>
                            <Input type={'text'} value={userInput.voter}
                                   onChange={(event) => setUserInput({...userInput, voter: event.target.value})}/>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md={'3'}>
                          <Label>PAN Number</Label>
                          <FormGroup>
                            <Input type={'text'} value={userInput.pan}
                                   onChange={(event) => setUserInput({...userInput, pan: event.target.value})}/>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md={'3'}>
                          <Label>Monthly Income</Label>
                          <FormGroup>
                            <Input type={'number'} value={userInput.income}
                                   onChange={(event) => setUserInput({...userInput, income: event.target.value})}/>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md={'3'}>
                          <Label>Occupation</Label>
                          <FormGroup>
                            <Input type={'text'} value={userInput.occupation}
                                   onChange={(event) => setUserInput({...userInput, occupation: event.target.value})}/>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md={'3'}>
                          <Label>Educational Qualification</Label>
                          <FormGroup>
                            <Input type={'text'} value={userInput.education}
                                   onChange={(event) => setUserInput({...userInput, education: event.target.value})}/>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md={'12'}>
                          <Label>Full Address with Pin Code</Label>
                          <FormGroup>
                            <Input type={'textarea'} value={userInput.address} aria-colspan={3}
                                   onChange={(event) => setUserInput({...userInput, address: event.target.value})}/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col md={3} className={'mt-auto mb-auto'}>
                    <ProfileImageUpload
                      id={'profile'}
                      uuid={userInput.uuid}
                      bankId={authStatus.bankId}
                      changeBtnClasses="btn-simple"
                      addBtnClasses="btn-simple"
                      removeBtnClasses="btn-simple"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h3">Nominee Details</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="3">
                      <Label>Nominee Name</Label>
                      <FormGroup>
                        <Input type={'text'} value={userInput.nominee.name}
                               onChange={(event) => setUserInput({
                                 ...userInput,
                                 nominee: {...userInput.nominee, name: event.target.value}
                               })}/>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <Label>Relation</Label>
                      <FormGroup>
                        <Input type={'text'} value={userInput.nominee.relation}
                               onChange={(event) => setUserInput({
                                 ...userInput,
                                 nominee: {...userInput.nominee, relation: event.target.value}
                               })}/>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <Label>Date of Birth</Label>
                      <FormGroup>
                        <Input type={'date'} value={userInput.nominee.dob}
                               onChange={(event) => setUserInput({
                                 ...userInput,
                                 nominee: {...userInput.nominee, dob: event.target.value}
                               })}/>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <Label>Aadhar Number</Label>
                      <FormGroup>
                        <Input type={'text'} value={userInput.nominee.aadhar}
                               onChange={(event) => setUserInput({
                                 ...userInput,
                                 nominee: {...userInput.nominee, aadhar: event.target.value}
                               })}/>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <Label>Voter Number</Label>
                      <FormGroup>
                        <Input type={'text'} value={userInput.nominee.voter}
                               onChange={(event) => setUserInput({
                                 ...userInput,
                                 nominee: {...userInput.nominee, voter: event.target.value}
                               })}/>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <Label>PAN Number</Label>
                      <FormGroup>
                        <Input type={'text'} value={userInput.nominee.pan}
                               onChange={(event) => setUserInput({
                                 ...userInput,
                                 nominee: {...userInput.nominee, pan: event.target.value}
                               })}/>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12" className={'text-center'}>
            <CardFooter>
              <center>
                <Spinner color="info" hidden={!progressbar}/>
              </center>
              <Button className="btn-fill" color="info" type="button" onClick={onSubmit}>
                Add New Member
              </Button>
            </CardFooter>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditMember;
