/*!

=========================================================
* Black Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";

import { initializeApp } from "firebase/app";
import { Provider } from "react-redux";
import store from "./store";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_ENV === 'prod'){

}else {
  initializeApp({
    apiKey: "AIzaSyCzpJf0zhMYf0x0cMr5N1yg8_IMe0G4R-A",
    authDomain: "quantum-weft-431512-q2.firebaseapp.com",
    databaseURL: "https://quantum-weft-431512-q2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "quantum-weft-431512-q2",
    storageBucket: "quantum-weft-431512-q2.firebasestorage.app",
    messagingSenderId: "375950959806",
    appId: "1:375950959806:web:395d5a06ab834cea11eaef",
    measurementId: "G-85HEG1XBLH"
  });
}

document.body.classList.add("white-content");
document.body.classList.add("sidebar-mini");

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
