
    /*!

=========================================================
* Black Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


    import React from "react";
    import {
        Button,
        Card,
        CardHeader,
        CardBody,
        CardFooter,
        CardTitle,
        Label,
        FormGroup,
        Form,
        Input,
        Row,
        Col, Spinner,
    } from "reactstrap";
    import ProfileImageUpload from "../../components/ProfileImageUpload";
    import axios from "axios";
    import CstNotification from "../../components/CstNotification";
    import ReactBSAlert from "react-bootstrap-sweetalert";
    import {useSelector} from "react-redux";

    const LoanRepaymentForm =() => {
        // Initialize the default state for the form inputs
        const initInput = {
            // Loan Details
            loanId:"",
            loanDate: new Date().toISOString().slice(0, 10),
            searchMember: "",
            relativeDetails: "",
            dob: "",
            mobileNo: "",
            smsStatus: "",
            address: "",
            pinCode: "",
            branchName: "",
            loanPlanName: "",
            loanType: "",
            planTerm: "",
            loanMode: "",
            roiPercentage: "",
            loanAmount: "",
            roiType: "",
            emiAmount: "",
            loanPurpose: "",
            // Guarantor Details
            guarantor: {
                memberCode: "",
                guarantorName: "",
                address: "",
                pinCode: "",
                phone: "",
                securityType: "",
            },
            // Co-Applicant Details
            coApplicant: {
                memberCode: "",
                coApplicantname: "",
                address: "",
                pinCode: "",
                phone: "",
                securityDetails: "",
            },
            // Deduction Details
            deductionDetails: {
                processingFee: "",
                legalAmount: "",
                advisorCode:"",
                gst: "",
                insuranceAmount: "",
                advisorCollectorCode: "",
            },
            // Photo and Signature
            photo: null,
            signature: null,
            uuid: crypto.randomUUID(),
        };

        const [formData, setFormData] = React.useState(initInput);

        // Initialize the state for error tracking
        const [cstError, setCstError] = React.useState({
            loanDate: "",
            searchMember: "",
            relativeDetails: "",
            dob: "",
            mobileNo: "",
            smsStatus: "",
            address: "",
            pinCode: "",
            branchName: "",
            loanPlanName: "",
            loanType: "",
            planTerm: "",
            loanMode: "",
            roiPercentage: "",
            loanAmount: "",
            roiType: "",
            emiAmount: "",
            loanPurpose: "",
        });
        const [progressbar, setProgressbar] = React.useState(false);
        const [alert, setAlert] = React.useState({
            color: 'success',
            message: 'test message',
            autoDismiss: 7,
            place: 'tc',
            display: false,
            sweetAlert: false,
        });
        const authStatus = useSelector((state) => state.auth.authState);




        const validateForm = () => {
            let formErrors = {};
            let isValid = true;


            if (!formData.loanDate) {
                formErrors.loanDate = "Loan Date is required.";
                isValid = false;
            }
            if (!formData.searchMember) {
                formErrors.searchMember = "Input hare.";
                isValid = false;
            }
            if (!formData.relativeDetails) {
                formErrors.relativeDetails = "Relative Details is required.";
                isValid = false;
            }
            if (!formData.dob) {
                formErrors.dob = "Date of birth is required.";
                isValid = false;
            }
            if (!formData.mobileNo || formData.mobileNo.length !== 10) {
                formErrors.mobileNo = "A valid 10-digit Mobile No is required.";
                isValid = false;
            }
            if (!formData.smsStatus) {
                formErrors.smsStatus = "Please input hare.";
                isValid = false;
            }
            if (!formData.address) {
                formErrors.address = "Address Is required.";
                isValid = false;
            }
            if (!formData.pinCode) {
                formErrors.pinCode = "Pin code Is required.";
                isValid = false;
            }
            if (!formData.branchName) {
                formErrors.branchName = "Branch Name Is required.";
                isValid = false;
            }
            if (!formData.loanPlanName) {
                formErrors.loanPlanName = "Loan Plan Name Is required.";
                isValid = false;
            }
            if (!formData.loanType) {
                formErrors.loanType = "Loan Type Is required.";
                isValid = false;
            }
            if (!formData.planTerm) {
                formErrors.planTerm = "Plan Term Is required.";
                isValid = false;
            }
            if (!formData.loanMode) {
                formErrors.loanMode = "Loan Mode Is required.";
                isValid = false;
            }
            if (!formData.roiPercentage) {
                formErrors.roiPercentage = "ROI Percentage Is required.";
                isValid = false;
            }
            if (!formData.loanAmount) {
                formErrors.loanAmount = "Loan Amount Is required.";
                isValid = false;
            }
            if (!formData.roiType) {
                formErrors.roiType = "ROI Type Is required.";
                isValid = false;
            }
            if (!formData.emiAmount) {
                formErrors.emiAmount = "EMI Amount Is required.";
                isValid = false;
            }
            if (!formData.loanPurpose) {
                formErrors.loanPurpose = "Loan Purpose Is required.";
                isValid = false;
            }
            // Deduction Details Validation
            if (!formData.deductionDetails.processingFee) {
                formErrors.processingFee = "Processing Fee is required.";
                isValid = false;
            }
            if (!formData.deductionDetails.legalAmount) {
                formErrors.legalAmount = "Legal Amount is required.";
                isValid = false;
            }
            if (!formData.deductionDetails.advisorCode) {
                formErrors.advisorCode = "Advisor Code is required.";
                isValid = false;
            }
            if (!formData.deductionDetails.gst) {
                formErrors.gst = "GST is required.";
                isValid = false;
            }
            if (!formData.deductionDetails.insuranceAmount) {
                formErrors.insuranceAmount = "Insurance Amount is required.";
                isValid = false;
            }
            if (!formData.deductionDetails.advisorCollectorCode) {
                formErrors.advisorCollectorCode = "Advisor Collector Code is required.";
                isValid = false;
            }
            setCstError(formErrors);
            return isValid;
        };
        // Handle input change for simple fields
        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        };

        // Handle input change for nested fields
        const handleNestedInputChange = (section, field, value) => {
            setFormData({
                ...formData,
                [section]: {
                    ...formData[section],
                    [field]: value,
                },
            });
        };


        const handleSubmit = async () => {
            if (validateForm()) {
                try {
                    setProgressbar(true);
                    const submitData = await axios.post("/api/loan-Account/add", formData);
                    if (submitData.data.success) {
                        setFormData({...initInput,uuid:crypto.randomUUID(),});
                        setAlert({
                            color: 'success',
                            message: submitData.data.success,
                            autoDismiss: 7,
                            place: 'tc',
                            display: true,
                            sweetAlert: true,
                        });
                    } else {
                        setAlert({
                            color: 'warning',
                            message: submitData.data.error,
                            autoDismiss: 7,
                            place: 'tc',
                            display: true,
                            sweetAlert: false,
                        });
                    }
                    setProgressbar(false);
                } catch (e) {
                    setAlert({
                        color: 'danger',
                        message: e.toLocaleString(),
                        autoDismiss: 7,
                        place: 'tc',
                        display: true,
                        sweetAlert: false,
                    });
                }
                console.log(formData)
            }
        };


        return (
            <>
                <div className="rna-container">
                    {alert.display && <CstNotification color={alert.color} message={alert.message} autoDismiss={alert.autoDismiss} place={alert.place}/>}
                    {alert.sweetAlert && <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Good job!"
                        onConfirm={() => setAlert({...alert, sweetAlert: false})}
                        onCancel={() => setAlert({...alert, sweetAlert: false})}
                        confirmBtnBsStyle="success"
                        btnSize=""
                    >
                        {alert.message}
                    </ReactBSAlert>}
                </div>
                <div className="content">
                    <Row className="justify-content-center">
                        <Col md="12">
                            <Card>
                                <CardHeader className="text-center">
                                    <CardTitle tag="h3">Search Details</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row className="justify-content-center">
                                            <Col md="6">
                                                <FormGroup>
                                                    <Label for="loanId" className="text-center w-100">
                                                        Select by LoanID
                                                    </Label>
                                                    <Input
                                                        type="select"
                                                        id="loanId"
                                                        name="loanId"
                                                        value={formData.loanId}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select LoanID</option>
                                                        <option value="loan1">Loan ID 1</option>
                                                        <option value="loan2">Loan ID 2</option>
                                                        <option value="loan3">Loan ID 3</option>
                                                    </Input>
                                                </FormGroup>


                                                {/*<FormGroup>*/}
                                                {/*    <Input*/}
                                                {/*        type="select"*/}
                                                {/*        name="loanId"*/}
                                                {/*        value={formData.loanId}*/}
                                                {/*        onChange={handleInputChange}*/}
                                                {/*    >*/}
                                                {/*        <option value="">Select LoanID</option>*/}
                                                {/*        {loanIds.map((id) => (*/}
                                                {/*            <option key={id} value={id}>*/}
                                                {/*                {id}*/}
                                                {/*            </option>*/}
                                                {/*        ))}*/}
                                                {/*    </Input>*/}
                                                {/*</FormGroup>*/}

                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h3">Loan Details</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={9}>
                                            <Form>
                                                <Row className="d-flex flex-wrap">
                                                    <Col md="3">
                                                        <Label>Loan Date*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="date"
                                                                name="loanDate"
                                                                value={formData.loanDate}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.loanDate}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Search Member*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="searchMember"
                                                                value={formData.searchMember}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.searchMember}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Relative Details*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="relativeDetails"
                                                                value={formData.relativeDetails}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.relativeDetails}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Date of Birth*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="date"
                                                                name="dob"
                                                                value={formData.dob}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.dob}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Mobile No*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="number"
                                                                name="mobileNo"
                                                                value={formData.mobileNo}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.mobileNo}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>SMS Status*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="smsStatus"
                                                                value={formData.smsStatus}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.smsStatus}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Address*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="address"
                                                                value={formData.address}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.address}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Pin Code*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="number"
                                                                name="pinCode"
                                                                value={formData.pinCode}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.pinCode}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Branch Name*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="branchName"
                                                                value={formData.branchName}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.branchName}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Loan Plan Name*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="loanPlanName"
                                                                value={formData.loanPlanName}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.loanPlanName}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Loan Type*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="loanType"
                                                                value={formData.loanType}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.loanType}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Plan Term*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="planTerm"
                                                                value={formData.planTerm}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.planTerm}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Loan Mode*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="loanMode"
                                                                value={formData.loanMode}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.loanMode}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>ROI Percentage*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="number"
                                                                name="roiPercentage"
                                                                value={formData.roiPercentage}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.roiPercentage}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Loan Amount*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="number"
                                                                name="loanAmount"
                                                                value={formData.loanAmount}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.loanAmount}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>ROI Type*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="roiType"
                                                                value={formData.roiType}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.roiType}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>EMI Amount*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="number"
                                                                name="emiAmount"
                                                                value={formData.emiAmount}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.emiAmount}</p>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="3">
                                                        <Label>Loan Purpose*</Label>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="loanPurpose"
                                                                value={formData.loanPurpose}
                                                                onChange={handleInputChange}
                                                            />
                                                            <p style={{color: 'red'}}>{cstError.loanPurpose}</p>
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                            </Form>
                                        </Col>



                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <Card>
                                <CardHeader tag="h3">Photo Upload</CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md={3} className={'mt-auto mb-auto'}>

                                                <ProfileImageUpload
                                                    id={'photo'}
                                                    uuid={formData.uuid}
                                                    bankId={authStatus.bankId}
                                                    changeBtnClasses="btn-simple"
                                                    addBtnClasses="btn-simple"
                                                    removeBtnClasses="btn-simple"
                                                />
                                            </Col>
                                            <Col md={3} className={'mt-auto mb-auto'}>

                                                <ProfileImageUpload
                                                    id={'signature'}
                                                    uuid={formData.uuid}
                                                    bankId={authStatus.bankId}
                                                    changeBtnClasses="btn-simple"
                                                    addBtnClasses="btn-simple"
                                                    removeBtnClasses="btn-simple"
                                                />
                                            </Col>


                                        </Row>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>






                    {/*Guarantor Details*/}
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h3">Guarantor Details</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md="3">
                                                <Label>Member Code</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="memberCode"
                                                        value={formData.guarantor.memberCode}
                                                        onChange={(e) => handleNestedInputChange('guarantor', 'memberCode', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.guarantor?.memberCode}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Guarantor Name</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="guarantorName"
                                                        value={formData.guarantor.guarantorName}
                                                        onChange={(e) => handleNestedInputChange('guarantor', 'guarantorName', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.guarantor?.guarantorName}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Address</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="address"
                                                        value={formData.guarantor.address}
                                                        onChange={(e) => handleNestedInputChange('guarantor', 'address', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.guarantor?.address}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Pin Code</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="number"
                                                        name="pinCode"
                                                        value={formData.guarantor.pinCode}
                                                        onChange={(e) => handleNestedInputChange('guarantor', 'pinCode', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.guarantor?.pinCode}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Phone</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="number"
                                                        name="phone"
                                                        value={formData.guarantor.phone}
                                                        onChange={(e) => handleNestedInputChange('guarantor', 'phone', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.guarantor?.phone}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Security Type</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="securityType"
                                                        value={formData.guarantor.securityType}
                                                        onChange={(e) => handleNestedInputChange('guarantor', 'securityType', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.guarantor?.securityType}</p>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    {/*Co-Applicant Details*/}
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h3">Co-Applicant Details</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md="3">
                                                <Label>Member Code</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="memberCode"
                                                        value={formData.coApplicant.memberCode}
                                                        onChange={(e) => handleNestedInputChange('coApplicant', 'memberCode', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.coApplicant?.memberCode}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Co-Applicant Name</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="coApplicantname"
                                                        value={formData.coApplicant.coApplicantname}
                                                        onChange={(e) => handleNestedInputChange('coApplicant', 'coApplicantname', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.coApplicant?.coApplicantname}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Address</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="address"
                                                        value={formData.coApplicant.address}
                                                        onChange={(e) => handleNestedInputChange('coApplicant', 'address', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.coApplicant?.address}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Pin Code</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="number"
                                                        name="pinCode"
                                                        value={formData.coApplicant.pinCode}
                                                        onChange={(e) => handleNestedInputChange('coApplicant', 'pinCode', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.coApplicant?.pinCode}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Phone</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="number"
                                                        name="phone"
                                                        value={formData.coApplicant.phone}
                                                        onChange={(e) => handleNestedInputChange('coApplicant', 'phone', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.coApplicant?.phone}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Security Details</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="securityDetails"
                                                        value={formData.coApplicant.securityDetails}
                                                        onChange={(e) => handleNestedInputChange('coApplicant', 'securityDetails', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.coApplicant?.securityDetails}</p>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/*Deduction Details*/}
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h3">Deduction Details</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md="3">
                                                <Label>Processing Fee</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="number"
                                                        name="processingFee"
                                                        value={formData.deductionDetails.processingFee}
                                                        onChange={(e) => handleNestedInputChange('deductionDetails', 'processingFee', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.deductionDetails?.processingFee}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Legal Amount</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="number"
                                                        name="legalAmount"
                                                        value={formData.deductionDetails.legalAmount}
                                                        onChange={(e) => handleNestedInputChange('deductionDetails', 'legalAmount', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.deductionDetails?.legalAmount}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Advisor Code</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="advisorCode"
                                                        value={formData.deductionDetails.advisorCode}
                                                        onChange={(e) => handleNestedInputChange('deductionDetails', 'advisorCode', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.deductionDetails?.advisorCode}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>GST</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="gst"
                                                        value={formData.deductionDetails.gst}
                                                        onChange={(e) => handleNestedInputChange('deductionDetails', 'gst', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.deductionDetails?.gst}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label>Insurance Amount</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="number"
                                                        name="insuranceAmount"
                                                        value={formData.deductionDetails.insuranceAmount}
                                                        onChange={(e) => handleNestedInputChange('deductionDetails', 'insuranceAmount', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.deductionDetails?.insuranceAmount}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <Label>Advisor Collector Code</Label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="advisorCollectorCode"
                                                        value={formData.deductionDetails.advisorCollectorCode}
                                                        onChange={(e) => handleNestedInputChange('deductionDetails', 'advisorCollectorCode', e.target.value)}
                                                    />
                                                    <p style={{color: 'red'}}>{cstError.deductionDetails?.advisorCollectorCode}</p>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </Form>
                                </CardBody>
                                <Col md="12" className={'text-center'}>
                                    <CardFooter>
                                        <center>
                                            <Spinner color="info" hidden={!progressbar}/>
                                        </center>
                                        <Button className="btn-fill" color="info" type="button" onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                    </CardFooter>
                                </Col>
                            </Card>
                        </Col>
                    </Row>





                </div>

            </>
        );
    };

    export default LoanRepaymentForm;



