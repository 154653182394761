import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import AuthLayout from "./layouts/Auth/Auth";
import AdminLayout from "./layouts/Admin/Admin";
import RTLLayout from "./layouts/RTL/RTL";
import Login from "views/custom/auth/Login";
import React from "react";

import axios from "axios";
import {getAuthState} from "./reducers/authReducer";
import {useDispatch, useSelector} from "react-redux";

import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community';

function App() {
  // Get Current Auth state
  const authDispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.authState);
  ModuleRegistry.registerModules([AllCommunityModule]);
  const [fetched, setFetched] = React.useState(false);

// Dispatch Auth validation on DOM load or auth state change
  React.useEffect( () => {
    if (!fetched){
      setFetched(true);
      axios.get("/api/auth/get-user").then((fetchUser) => {
        authDispatch(getAuthState(fetchUser.data));
      }).catch((e) => {
        console.log(e);
        authDispatch(
          getAuthState({
            loggedIn: false,
            bankId: "",
            email: "",
            name: "",
            permissions: {},
            bankInfo: {},
          })
        );
        if (window.location.pathname !== "/"){
          window.location.href = "/";
        }
      });
    }
  }, [authStatus]);

  return (<>
    <BrowserRouter>
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/admin/*" element={<AdminLayout />} />

        {/*Custom Layout Routes*/}
        <Route path="/member/*" element={<AdminLayout />} />
        <Route path="/deposit/*" element={<AdminLayout />} />
        <Route path="/loan/*" element={<AdminLayout />} />
        <Route path="/authorization/*" element={<AdminLayout />} />
        <Route exact path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />

        {/* This is OOB Layout, will be implemented later if required*/}
        <Route path="/rtl/*" element={<RTLLayout />} />
      </Routes>
    </BrowserRouter>
  </>)
}

export default App;





