import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  Label, Spinner
} from "reactstrap";
import axios from "axios";
import CstNotification from "../../components/CstNotification";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

const PlanCreation = () => {
  const initialState = {
    id: '',
    name: '',
    type: '',
    emiMode: '',
    minAge: '',
    maxAge: '',
    minAmount: '',
    maxAmount: '',
    minTerm: '',
    maxTerm: '',
    interestRate: '',
    interestType: '',
    security: '',
    processingFee: 0,
    legalFee: 0,
    insuranceFeeRate: 0,
    gstRate: 0,
    valuerFeeRate: 0,
    gracePeriod: 0,
    penaltyType: '',
    penaltyRate: '',
  };

  const [formData, setFormData] = React.useState(initialState);
  const [cstError, setCstError] = React.useState({...initialState, gracePeriod: ''});
  const [planList, setPlanList] = React.useState([]);
  const [alert, setAlert] = React.useState({
    color: 'success',
    message: 'test message',
    autoDismiss: 7,
    place: 'tc',
    display: false,
    sweetAlert: false,
  });
  const [progressbar, setProgressbar] = React.useState(false);
  const [fetched, setFetched] = React.useState(false);

  if (!fetched){
    setFetched(true);
    axios.get('/api/loan/get-plans')
      .then(function (value){
        if (value.data.success){
          if (value.data.plans.length > 0){
            setPlanList(value.data.plans);
          }else {
            setAlert({
              color: 'warning',
              message: 'No plan found! Please add first plan.',
              autoDismiss: 7,
              place: 'tc',
              display: true,
              sweetAlert: false,
            });
          }
        }else {
          setAlert({
            color: 'danger',
            message: value.data.error,
            autoDismiss: 7,
            place: 'tc',
            display: true,
            sweetAlert: false,
          });
        }
      }).catch(function (error){
      setAlert({
        color: 'danger',
        message: error.toLocaleString(),
        autoDismiss: 7,
        place: 'tc',
        display: true,
        sweetAlert: false,
      });
    });
  }

  const validateForm = () => {
    let formErrors = {...initialState, gracePeriod: ''};
    let isValid = true;
    if (!formData.id) {
      formErrors.id = "Plan Id is required.";
      isValid = false;
    }
    if (!formData.name) {
      formErrors.name = "Plan name is required.";
      isValid = false;
    }
    if (!formData.type) {
      formErrors.type = "Loan type is required.";
      isValid = false;
    }
    if (!formData.emiMode) {
      formErrors.emiMode = "Loan EMI mode is required.";
      isValid = false;
    }
    if (!formData.minAmount) {
      formErrors.minAmount = "Min age is required.";
      isValid = false;
    }
    if (!formData.maxAmount) {
      formErrors.maxAmount = "Max age is required.";
      isValid = false;
    }
    if (!formData.minTerm) {
      formErrors.minTerm = "Min term is required.";
      isValid = false;
    }
    if (!formData.maxTerm) {
      formErrors.maxTerm = "Max term is required.";
      isValid = false;
    }
    if (!formData.interestRate) {
      formErrors.interestRate = "Interest rate is required.";
      isValid = false;
    }
    if (!formData.security) {
      formErrors.security = "Security type is required.";
      isValid = false;
    }
    if (!formData.gracePeriod) {
      formErrors.gracePeriod = "Grace period is required.";
      isValid = false;
    }
    if (!formData.penaltyType) {
      formErrors.penaltyType = "Penalty type is required.";
      isValid = false;
    }
    if (!formData.penaltyRate) {
      formErrors.penaltyRate = "Penalty rate is required.";
      isValid = false;
    }
    setCstError(formErrors);
    return isValid;
  };

  const handleClear = () => {
    setFormData(initialState);
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setProgressbar(true);
      try {
        const submitData = await axios.post('/api/loan/plan-creation', formData);
        if (submitData.data.success) {
          setPlanList([...planList, formData]);
          setAlert({
            color: 'success',
            message: submitData.data.success,
            autoDismiss: 7,
            place: 'tc',
            display: false,
            sweetAlert: true,
          });
        }else {
          setAlert({
            color: 'danger',
            message: 'Failed to add department!',
            autoDismiss: 7,
            place: 'tc',
            display: false,
            sweetAlert: true,
          });
        }
        setProgressbar(false);
      }catch (e) {
        setAlert({
          color: 'danger',
          message: e.toLocaleString(),
          autoDismiss: 7,
          place: 'tc',
          display: false,
          sweetAlert: true,
        });
      }
    }
  };

  return (
    <>
      <div className="rna-container">
        {alert.display && <CstNotification color={alert.color} message={alert.message} autoDismiss={alert.autoDismiss} place={alert.place} />}
        {alert.sweetAlert && <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success!"
          onConfirm={() => setAlert({ ...alert, sweetAlert: false })}
          onCancel={() => setAlert({ ...alert, sweetAlert: false })}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          {alert.message}
        </ReactBSAlert>}
      </div>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h3">Plan Master</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>Loan Plan Id *</Label>
                      <Input
                        type="text"
                        name="id"
                        value={formData.id}
                        onChange={({ target }) => setFormData({ ...formData, id: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.departmentId}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Loan Name *</Label>
                      <Input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={({ target }) => setFormData({ ...formData, name: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.name}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Loan Type *</Label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="typeSelect"
                        onChange={({ value }) => setFormData({...formData, type: value })}
                        options={[
                          {value: 'Property Loan', label: 'Property Loan'},
                          {value: 'Personal Loan', label: 'Personal Loan'},
                          {value: 'Vehicle Loan', label: 'Vehicle Loan'},
                          {value: 'Business Loan', label: 'Business Loan'},
                          {value: 'Home Loan', label: 'Home Loan'},
                          {value: 'Micro Loan', label: 'Micro Loan'},
                          {value: 'Loan Against Policy', label: 'Loan Against Policy'},
                          {value: 'Other Loan', label: 'Other Loan'},
                        ]}
                        placeholder="Select an Option"
                      />
                      <p style={{ color: 'red' }}>{cstError.type}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Loan EMI Mode *</Label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="emiModeSelect"
                        onChange={({ value }) => setFormData({...formData, emiMode: value })}
                        options={[
                          {value: 'daily', label: 'Daily'},
                          {value: 'weekly', label: 'Weekly'},
                          {value: 'fortnightly', label: 'Fortnightly'},
                          {value: 'monthly', label: 'Monthly'},
                          {value: 'quarterly', label: 'Quarterly'},
                        ]}
                        placeholder="Select an Option"
                      />
                      <p style={{ color: 'red' }}>{cstError.emiMode}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Min Age *</Label>
                      <Input
                        type="number"
                        name="minAge"
                        value={formData.minAge}
                        onChange={({ target }) => setFormData({ ...formData, minAge: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.minAge}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Max Age *</Label>
                      <Input
                        type="number"
                        name="maxAge"
                        value={formData.maxAge}
                        onChange={({ target }) => setFormData({ ...formData, maxAge: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.maxAge}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Min Amount *</Label>
                      <Input
                        type="number"
                        name="minAmount"
                        value={formData.minAmount}
                        onChange={({ target }) => setFormData({ ...formData, minAmount: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.minAmount}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Max Amount *</Label>
                      <Input
                        type="number"
                        name="maxAmount"
                        value={formData.maxAmount}
                        onChange={({ target }) => setFormData({ ...formData, maxAmount: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.maxAmount}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Min Term (month) *</Label>
                      <Input
                        type="number"
                        name="minTerm"
                        value={formData.minTerm}
                        onChange={({ target }) => setFormData({ ...formData, minTerm: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.minTerm}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Max Term (month) *</Label>
                      <Input
                        type="number"
                        name="maxTerm"
                        value={formData.maxTerm}
                        onChange={({ target }) => setFormData({ ...formData, maxTerm: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.maxTerm}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Interest Rate *</Label>
                      <Input
                        type="number"
                        name="interestRate"
                        value={formData.interestRate}
                        onChange={({ target }) => setFormData({ ...formData, interestRate: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.interestRate}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Security Type *</Label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="securitySelect"
                        onChange={({ value }) => setFormData({...formData, security: value })}
                        options={[
                          {value: 'PLEDGE', label: 'PLEDGE'},
                          {value: 'GUARANTEE', label: 'GUARANTEE'},
                          {value: 'MORTGAGE', label: 'MORTGAGE'},
                          {value: 'OTHER', label: 'OTHER'},
                        ]}
                        placeholder="Select an Option"
                      />
                      <p style={{ color: 'red' }}>{cstError.emiMode}</p>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h3">Deduction Details</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>Processing Fee (%) *</Label>
                      <Input
                        type="number"
                        name="processingFee"
                        value={formData.processingFee}
                        onChange={({ target }) => setFormData({ ...formData, processingFee: target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Legal Fee (₹) *</Label>
                      <Input
                        type="number"
                        name="legalFee"
                        value={formData.legalFee}
                        onChange={({ target }) => setFormData({ ...formData, legalFee: target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Insurance Fee (%) *</Label>
                      <Input
                        type="number"
                        name="insuranceFeeRate"
                        value={formData.insuranceFeeRate}
                        onChange={({ target }) => setFormData({ ...formData, insuranceFeeRate: target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>GST (%) *</Label>
                      <Input
                        type="number"
                        name="gstRate"
                        value={formData.gstRate}
                        onChange={({ target }) => setFormData({ ...formData, gstRate: target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Valuer Fee (%) *</Label>
                      <Input
                        type="number"
                        name="valuerFeeRate"
                        value={formData.valuerFeeRate}
                        onChange={({ target }) => setFormData({ ...formData, valuerFeeRate: target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h3">Penalty Details</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>Grace Period (month) *</Label>
                      <Input
                        type="number"
                        name="gracePeriod"
                        value={formData.gracePeriod}
                        onChange={({ target }) => setFormData({ ...formData, gracePeriod: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.gracePeriod}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Penalty Type *</Label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="penaltyTypeSelect"
                        onChange={({ value }) => setFormData({...formData, penaltyType: value })}
                        options={[
                          {value: 'amount', label: 'Amount'},
                          {value: 'percentage', label: 'Percentage'},
                        ]}
                      />
                      <p style={{ color: 'red' }}>{cstError.penaltyType}</p>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Penalty Rate *</Label>
                      <Input
                        type="number"
                        name="penaltyRate"
                        value={formData.penaltyRate}
                        onChange={({ target }) => setFormData({ ...formData, penaltyRate: target.value })}
                      />
                      <p style={{ color: 'red' }}>{cstError.penaltyRate}</p>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="12" className={"text-center"}>
            <Button color="success" onClick={handleSubmit} disabled={progressbar} className={"mr-2"}>
              {progressbar ? <Spinner color="light" style={{ width: '1rem', height: '1rem' }} /> : 'Submit'}
            </Button>
            <Button color="danger" onClick={handleClear}>
              Delete
            </Button>
          </Col>
        </Row>
        <Row className={"mt-4"}>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h3">Loan Plan List</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr>
                    <th>SNo</th>
                    <th>Plan Code</th>
                    <th>Plan Name</th>
                    <th>EMI Mode</th>
                    <th>Min Amount</th>
                    <th>Max Amount</th>
                    <th>Interest Rate</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {planList.map((value, index) => (
                    <tr key={value.id}>
                      <td>{index + 1}</td>
                      <td>{value.id}</td>
                      <td>{value.name}</td>
                      <td>{value.emiMode}</td>
                      <td>{value.minAmount}</td>
                      <td>{value.maxAmount}</td>
                      <td>{value.interestRate}</td>
                      <td>
                        <Button color="primary" size="sm" onClick={() => setFormData(value)}>
                          ➡
                        </Button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PlanCreation;